$(document).on('turbolinks:load', function () {
  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $('#image_preview').attr('src', e.target.result);
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  $("#post_img").change(function () {
    $('#image_preview').removeClass('d-none');
    $('.image_present').remove();
    readURL(this);
  });
});