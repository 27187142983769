var remove_tag_to_hash_tag_box = function(tagname) {
  var tagname = "#" + tagname + " ";
  var value = $('#hash_tag').val().replace(tagname, "");
  $('#hash_tag').val(value);
  return true;
}
var add_tag_to_hash_tag_box = function(tagname) {
  var tagname = "#" + tagname + " ";
  var value = $('#hash_tag').val() + tagname;
  $('#hash_tag').val(value);
  return true;
}

var add_parameters = function(tagname, taggroupid) {
  var parameters = {
    url: location.protocol + '//' + location.host + '/tags',
    method: 'post',
    data: {
      tagname: tagname,
      taggroupid: taggroupid
    }
  }
  return parameters;
}

var remove_parameters = function(tagname, taggroupid) {
  var parameters = {
    url: location.protocol + '//' + location.host + '/tags',
    method: 'delete',
    data: {
      tagname: tagname,
      taggroupid: taggroupid
    }
  }
  return parameters;
}

var add_count_add_tag = function() {
  $.ajax({
    url: location.href + '/count_add_tag',
    method: 'post'
  });
};

var add_count_remove_tag = function() {
  $.ajax({
    url: location.href + '/count_remove_tag',
    method: 'post'
  });
};

var call_relations_by_api = function(tagname, taggroupid) {
  $.ajax({
    url: location.protocol + '//' + location.host + '/tags/relations_by_api',
    method: 'post',
    data: {
      search_word: tagname,
      taggroupid: taggroupid
    }
  }).done(function (data) {
    $('.card-body').find('.add_tags').prepend(data);
  });
};

$(function () {
  $(document).on('click', '.checked-label a.checked-close', function (ev) {
    var tagname = $(ev.target).data('tagname');
    var taggroupid = $(ev.target).data('taggroupid');
    var parameters = remove_parameters(tagname, taggroupid);    
    $.ajax(parameters).done(function (data) {
      $('.add_tags').append(data);
      return
    });
    remove_tag_to_hash_tag_box(tagname);
    add_count_remove_tag();
    $(ev.target).parents('.checked-label').remove();
    return false;
  });
});

$(function () {
  $(document).on('click', '.checked-label a.checked-add', function (ev) {
    var tagname = $(ev.target).data('tagname');
    var taggroupid = $(ev.target).data('taggroupid');
    var parameters = add_parameters(tagname, taggroupid);    
    $.ajax(parameters).done(function (data) {
      $('.remove_tags').append(data);
      return
    });
    add_tag_to_hash_tag_box(tagname);
    add_count_add_tag();
    $(ev.target).parents('.checked-label').remove();
    return false;
  });
});

$(function () {
  $(document).on('keypress', 'input.input-autocomplete', function (ev) {
    if (ev.which === 13) {
      var tagname = $(ev.target).val();
      var taggroupid = $(ev.target).data('taggroupid');
      var parameters = add_parameters(tagname, taggroupid);
      $.ajax(parameters).done(function (data) {
        var id = $(data).attr('id');
        if (!$(ev.target).parents('.autocomplete-wrap').find('.remove_tags').find("#" + id).attr('id')) {
          add_tag_to_hash_tag_box(tagname);
          $(ev.target).parents('.autocomplete-wrap').find('.remove_tags').append(data);
          add_count_add_tag();
          call_relations_by_api(tagname, taggroupid);
          return
        }
      });
      $(ev.target).val('');
      return false;
    }
  });
});

$(function () {
  $(document).on('click', '#images_tags_enter', function (ev) {
    var tagname =  $('#tags').val();
    var taggroupid = $('#tags').data('taggroupid');
    var parameters = add_parameters(tagname, taggroupid);
    $.ajax(parameters).done(function (data) {
      var id = $(data).attr('id');
      if (!$(ev.target).parents('.autocomplete-wrap').find('.remove_tags').find("#" + id).attr('id')) {
        add_tag_to_hash_tag_box(tagname);
        $(ev.target).parents('.autocomplete-wrap').find('.remove_tags').append(data);
        add_count_add_tag();
        call_relations_by_api(tagname, taggroupid);
        return
      }
    });
    $('#tags').val('');
    return false;
  });
});

$(function () {
  $("#tags").autocomplete({
    source: $("#tags").data('path'),
    minLength: 2,
    select: function (ev, ui) {
      ev.preventDefault;
      ev.stopPropagation;
      var tagname = ui.item.value
      var taggroupid = $(ev.target).data('taggroupid');
      var parameters = add_parameters(tagname, taggroupid);
      $.ajax(parameters).done(function (data) {
        add_count_add_tag();
        call_relations_by_api(tagname, taggroupid);
        return $(ev.target).parents('.autocomplete-wrap').find('.remove_tags').append(data);
      });
      $(ev.target).val('').autocomplete('close');
      return false;
    }
  });
});

$(function () {
  $(document).on('click', '.btn-copy', function (ev) {
    $.ajax({
      url: location.href + '/count_copy_btn',
      method: 'post'
    });
    var value = $('#hash_tag').val();
    $('#modal_tag').val(value);
    return true;
  });
});
$(function () {
  $(document).on('click', '.btn-copy-tag-group', function (ev) {
    var value = $('#hash_tag').val();
    $('#modal_tag').val(value);
    return true;
  });
});

$(function () {
  $(document).on('click', '#ig_btn', function (ev) {
    $.ajax({
      url: location.href + '/count_ig_btn',
      method: 'post'
    });
    return true;
  });
});

$(function () {
  $(document).on('click', '#tw_btn', function (ev) {
    $.ajax({
      url: location.href + '/count_tw_btn',
      method: 'post'
    });
    return true;
  });
});

$(function () {
  $(document).on('click', '#fb_btn', function (ev) {
    $.ajax({
      url: location.href + '/count_fb_btn',
      method: 'post'
    });
    return true;
  });
});

$(function () {
  $(document).on('click', '#ln_btn', function (ev) {
    $.ajax({
      url: location.href + '/count_ln_btn',
      method: 'post'
    });
    return true;
  });
});
