$(function () {
  new ClipboardJS('.btn-copy').on('success', function (e) {
    var url = location.href;
    location.href = url + '/copied';
  });
});
$(function () {
  new ClipboardJS('.btn-copy-tag-group').on('success', function (e) {
    var url = location.href.replace('edit', 'copied');
    console.log(url);
    location.href = url;
  });
});
